import React from "react"
import { graphql, navigate } from "gatsby"

import Layout from "@components/Layout"
import SEO from "@components/Seo"
import { H1, H3 } from "@components/StyledElements"
import { Button } from "@components/Form"

const NotFoundPage = ({ data }) => {
  const banner = data.banner.edges[0].node.childImageSharp.fluid
  return (
    <Layout fluid={banner} alt={"Neil Hill Coaching"} height={300}>
      <div className="container-fluid">
        <SEO title="404: Page Not found" />
        <div className="row mt-4">
          <div className="col-12 text-center">
            <H1>Page Not found</H1>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 text-center">
            <H3>You just hit a route that doesn&#39;t exist.</H3>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 text-center">
            <Button onClick={() => navigate("/")}>Go Home</Button>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query NotFoundQuery {
    banner: allFile(filter: { name: { eq: "home_1790x325" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1790) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
  }
`

export default NotFoundPage
